<template>
  <div>
    <div class="row align-items-stretch">
      <div
        v-for="(news, index) in newsArticless"
        :key="index++"
        class="col-4 py-3"
      >
        <div class="card shadow border-0 p-0 h-100">
          <div class="img-card-top h-65 rounded-top">
            <img :src="news.image" class="rounded-top w-100 h-100" />
          </div>
          <div class="card-body h-35">
            <div class="h-75">
              <div class="fw-bold">
                {{ news.title }}
              </div>
            </div>

            <div class="d-flex justify-content-between h-25">
              <span>
                <div class="col text-secondary">
                  {{ news.publicationDate }}
                </div>
              </span>
              <button
                @click="
                  $router.push({
                    name: 'news-details',
                    params: { id: news.slug },
                  })
                "
                class="btn btn-outline-warning"
              >
                Afficher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("news/fetch");
  },
  computed: {
    ...mapGetters("news", { newsArticless: "getNewsArticles" }),
  },
};
</script>
